import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import OverlayContent from 'components/Header/OverlayContent';
import ThemeButton from 'components/buttons/ThemeButton';

const Wrapper = styled('div', {
    height: 'calc(var(--vh) - var(--height-header) - 100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [media.min['desktop.sm']]: {
        height: '100%',
    },
});

const Divider = styled('div', {
    height: '1px',
    backgroundColor: 'var(--color-border-basket)',
    width: '100%',
});

const EmptyBasket = ({ content }) => {
    const [css] = useStyletron();
    const { basketEmptyContent } = content;
    const filteredBasketEmptyContent = basketEmptyContent.filter(obj => obj.component !== 'button');
    const buttonComponent = basketEmptyContent.find(obj => obj.component === 'button');

    return (
        <Wrapper>
            <OverlayContent
                content={filteredBasketEmptyContent}
                wrapperStyles={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '144px 24px 0px',
                }}
            />
            {buttonComponent && (
                <>
                    <Divider />
                    <div className={css({ padding: '12px' })}>
                        <ThemeButton
                            $style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                height: '48px',
                                alignItems: 'center',
                            }}
                            as={Link}
                            fontKeys="Misc/20_120_-025"
                            rel={buttonComponent.follow}
                            to={buttonComponent.url}
                            theme={buttonComponent.theme}
                        >
                            {buttonComponent.label}
                        </ThemeButton>
                    </div>
                </>
            )}
        </Wrapper>
    );
};

EmptyBasket.propTypes = {
    content: PropTypes.object,
};

export default EmptyBasket;
